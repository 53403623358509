import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'PageView',
    component: (resolve) => require(['@/views/PageView'], resolve),
    children: [
      {
        path: '/',
        redirect: '/index'
      },
      {
        path: '/index',
        name: 'index',
        component: (resolve) => require(['@/views/home'], resolve),
        meta: {
          title: '首页'
        }
      },
      {
        path: '/service',
        name: 'service',
        component: (resolve) => require(['@/views/service/index.vue'], resolve),
        meta: {
          title: '产品中心'
        }
      },
      {
        path: '/about',
        component: () => import('@/views/about/index.vue'), // 公司介绍页面组件
        meta: {
          title: '公司介绍'
        },
        children: [
          {
            path: '/about',
            redirect: '/about/detail'
          },
          {
            path: '/about/detail',
            name: 'detail',
            component: (resolve) =>
              require(['@/views/about/detail.vue'], resolve),
            meta: {
              title: '公司详情'
            }
          },
          {
            path: '/about/team',
            name: 'team',
            component: (resolve) =>
              require(['@/views/about/team.vue'], resolve),
            meta: {
              title: '团队介绍'
            }
          }
        ]
      },
      {
        path: '/contact',
        name: 'contact',
        component: (resolve) => require(['@/views/contact/index.vue'], resolve),
        meta: {
          title: '联系方式'
        }
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/product/index.vue'), // 公司介绍页面组件
        meta: {
          title: '合作案例'
        },
        children: [
          {
            path: '/product',
            redirect: '/product/digitalize'
          },
          {
            path: '/product/digitalize',
            name: 'digitalize',
            component: (resolve) =>
              require(['@/views/product/digitalize.vue'], resolve),
            meta: {
              title: '合作案例'
            }
          },
          {
            path: '/product/ai',
            name: 'ai',
            component: (resolve) =>
              require(['@/views/product/ai.vue'], resolve),
            meta: {
              title: '合作案例'
            }
          },
          {
            path: '/product/rice',
            name: 'rice',
            component: (resolve) =>
              require(['@/views/product/rice.vue'], resolve),
            meta: {
              title: '合作案例'
            }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 在路由导航前的钩子函数中修改页面标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
