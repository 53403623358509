<template>
  <div class="footer">
    <div class="foot1">
      <div class="divv">
        <div style="display: flex; align-items: center">
          <img class="footimg" src="@/assets/phone.png" alt="" />
          <div class="div1" style="color: rgba(247, 147, 30, 1)">
            13466368278
          </div>
        </div>
        <div class="name">北京中软视信科技有限公司</div>
        <div class="name1">Beijing Chinasoft Vision Technology Co., Ltd</div>
        <div class="dizhi" style="color: rgba(51, 51, 51, 1)">
          地址：北京市海淀区青云里满庭芳园小区9号楼青云当代大厦5层511室
        </div>
        <div class="youxiang" style="color: rgba(51, 51, 51, 1)">
          邮箱：china-video@163.com
        </div>
      </div>
      <div class="divv">
        <img class="bottER" src="@/assets/images/erweima.jpg" alt="" />
      </div>
    </div>
    <div class="banquan">
      版权所有 © 2024 北京中软视信科技有限公司 |
      <a
        class="text-color"
        style="text-decoration: none"
        href="https://beian.miit.gov.cn"
        target="_blank"
      >
        京ICP备2024097106号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style lang="scss">
.text-color:hover {
  color: blue;
}
.text-color {
  color: #000000;
}
.bottER {
  width: 180px;
  height: 180px;
}
.foot1 {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(204, 204, 204, 1);
  padding-bottom: 35px;
  .divv {
  }
}
.banquan {
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  margin-top: 20px;
}
.footer {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
  background: #fff;
}
.footimg {
  width: 36px;
  height: 36px;
}
.div1 {
  font-size: 36px;
  margin-left: 15px;
}
.name {
  font-size: 24px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
  margin-top: 10px;
}
.name1 {
  font-size: 13px;
  color: rgba(51, 51, 51, 1);
  font-weight: bold;
}
.dizhi {
  font-size: 18px;
  margin-top: 5px;
}
.youxiang {
  font-size: 18px;
}
@media screen and (max-width: 1200px) {
  .footer {
    width: 100%;
    // line-height: 100px;
    padding: 110px 0;

    display: flex;
    justify-content: center;

    .footer-logo {
      width: 460px;
      height: 110px;
      img {
        width: 460px;
        height: 110px;
      }
    }
    .footer-marginTop35 {
      margin-top: 70px;
    }
    .footer-marginTop8 {
      margin-top: 8px;
    }

    .footer-content {
      font-size: 32px;
      line-height: 52px;
      letter-spacing: 14px;
      img {
        width: 64px;
        height: 64px;
      }
    }

    .footer-copyright {
      font-weight: 400;
      font-size: 28px;
      line-height: 52px;
      letter-spacing: 10px;
    }
  }
}
</style>
