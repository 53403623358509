<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <GoTop />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import GoTop from './components/GoTop.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    GoTop
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'pingfang';
  src: url('@/assets/pingfanggang.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  background: rgba(245, 245, 246, 1);
  font-family: SourceHanSansCN, SourceHanSansCN;
}
body {
  margin: 0;
  min-width: 1200px;
  background: rgba(245, 245, 246, 1);
}
</style>
